import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import { loadingscreen } from "../Store";
import ResetComponent from "./ResetComponent";

const ResetContainer = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [records, setRecords] = useState({});
  const [errors, setErrors] = useState([]);
  const { token } = useParams();
  const handleInput = (el) => {
    const change = { ...records };
    const input = el.target;
    change[input.name] = input.value;
    setRecords(change);
  };
  const reset = async () => {
    if (records.password !== records.password_confirm) {
      setErrors({ password_confirm: "Password tidak sama" });
      return;
    }
    try {
      setLoading(true);
      let response = await axios.post(`/students/${token}/reset`, records);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Succcess",
          text: response.data.message,
        });
      }
      setErrors([]);
      setLoading(false);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);
    }
  };
  return (
    <div className="w-full bg-slate-500 h-screen p-2">
      <ResetComponent
        handleInput={(e) => handleInput(e)}
        reset={() => reset()}
        records={records}
        errors={errors}
      />
      {errors.message ? (
        <div className="bg-red-500 p-4 px-6 mt-8 w-1/2 rounded mx-auto text-center text-white">
          {errors.message}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ResetContainer;
