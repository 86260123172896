import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import Input from "../Components/Input";

const ProfileForm = ({
  records,
  handleRecords,
  uploadPhoto,
  deletePhoto,
  errors,
}) => {
  return (
    <div className="bg-slate-100">
      <h2 className="border-b border-blue-300 uppercase py-2 px-4 bg-blue-600 text-white">
        edit profile
      </h2>
      <div className="p-3">
        <Input
          name="name"
          type="text"
          label="Nama"
          value={records.name ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="email"
          type="email"
          label="E-Mail"
          value={records.email ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="password"
          type="password"
          label="Password"
          description="Tidak perlu di isi, jika tidak ganti password"
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        {records?.photo !== null ? (
          <div className="mt-4">
            <label className="mb-4 block">Photo Profile</label>
            <div className="w-1/3 relative">
              <span onClick={() => deletePhoto("photo")}>
                <AiFillCloseCircle
                  className="absolute top-0 right-0 cursor-pointer text-red-400 hover:text-orange-300"
                  size={40}
                />
              </span>
              <img src={records.photo} alt="profile" />
            </div>
          </div>
        ) : (
          <Input
            name="photo"
            type="file"
            label="Upload Foto Profile"
            errors={errors}
            onChange={(e) => uploadPhoto(e)}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileForm;
