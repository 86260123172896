import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import TopBar from "../Components/TopBar";
import { loadingscreen } from "../Store";
import { get, post } from "../Utils/Axios";
import QuestionDetail from "./QuestionDetail";
import QuestionNumber from "./QuestionNumber";
import Timer from "./Timer";
import Button from "../Components/Button";
import { useNavigate } from "react-router-dom";

const QuestionContainer = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [exam, setExam] = useState(false);
  const [question, setQuestion] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [errors, setErrors] = useState([]);
  const countDownRef = useRef();
  const to_route = useNavigate();
  const changeNumberQuestion = (resquestion) => {
    const answer = resquestion.answer_id !== 0 ? "y" : "n";
    const find = exam.numbers.find((item) => {
      return item.number === resquestion.number;
    });
    const index = exam.numbers.indexOf(find);
    const change = { ...exam };
    change.numbers[index] = {
      number: resquestion.number,
      question_id: resquestion.id,
      answer: answer,
    };
    setExam(change);
  };
  const getStartExam = async () => {
    try {
      setLoading(true);
      const response = await get(`students/exam/question`);
      if (response.status === 200) {
        setExam(response.data);
        setQuestion(response.data.question);
      } else if (response.status === 204) {
        Swal.fire({
          icon: "info",
          title: "Pesan...",
          text: "Tidak dapat ujian karena waktu telah habis Atau telah menyelesaikan ujian",
        });
      }
      setLoading(false);
    } catch (error) {
      setErrors(error.response.data);
      setLoading(false);
    }
  };
  const getQuestion = async (question_id, number) => {
    try {
      setLoading(true);
      const answer = {
        question_id: question.id,
        answer_id: question.answer_id,
      };
      const records = {
        question_id,
        number,
        answer: answer,
      };
      const response = await post(`/students/question`, records);
      if (response.status === 200) {
        setQuestion(response.data);
        changeNumberQuestion(response.data);
      }
      setLoading(false);
      setRefresh(!refresh);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
    }
  };
  const changeNumberAnswer = (value) => {
    const answer = value !== 0 ? "y" : "n";
    const find = exam.numbers.find((item) => {
      return item.number === question.number;
    });
    const index = exam.numbers.indexOf(find);
    const change = { ...exam };
    change.numbers[index] = {
      number: question.number,
      question_id: question.id,
      answer: answer,
    };
    setExam(change);
  };
  const handleAnswer = (value) => {
    const change = { ...question };
    change.answer_id = value;
    setQuestion(change);
    changeNumberAnswer(value);
  };
  const doneExam = async () => {
    try {
      const answer = {
        question_id: question.id,
        answer_id: question.answer_id,
      };
      const response = await post(`/students/exam/done`, { answer: answer });
      if (response.status === 200) {
        clearInterval(countDownRef.current);
        Swal.fire({
          icon: "success",
          title: "Berhasil...",
          text: response.data.message,
        });
        to_route("/dashboards");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
    }
  };
  const doneConfirmation = () => {
    Swal.fire({
      title:
        "Yakin sudah selesai ?, Jika menekan tombol selesai maka ujian berakhir dan tidak dapat diulangi",
      showCancelButton: true,
      confirmButtonText: "Selesai",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doneExam();
      } else if (result.isDenied) {
      }
    });
  };

  useEffect(() => {
    getStartExam();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    // eslint-disable-next-line
  }, [question.id]);

  return (
    <div className="min-h-screen bg-slate-300">
      <TopBar />
      {errors.message ? (
        <div className="px-4 block mt-8 bg-red-400 text-white">
          {errors.message}
        </div>
      ) : (
        ""
      )}
      <div className="px-4 block mt-8">
        {errors.not_allowed ? (
          <div className="flex flex-col">
            <div className="bg-yellow-200 p-8 rounded text-center font-bold text-2xl">
              {errors.not_allowed}
            </div>
            <Button color="bg-orange-400 mt-8" onClick={() => to_route(-1)}>
              kembali
            </Button>
          </div>
        ) : (
          <Fragment>
            <div className="flex flex-col-reverse md:flex-row gap-3">
              <div className="bg-slate-100 w-full p-3 rounded shadow-lg">
                <QuestionDetail
                  question={question}
                  handleAnswer={(value) => handleAnswer(value)}
                />
                <div className="flex justify-between py-8">
                  {question.prev_number ? (
                    <Button
                      color="bg-orange-600"
                      onClick={() =>
                        getQuestion(
                          question.prev_question_id,
                          question.prev_number
                        )
                      }
                    >
                      Previous Question
                    </Button>
                  ) : (
                    <Button color="bg-orange-300" disabled={true}>
                      Previous Question
                    </Button>
                  )}
                  {question.next_number ? (
                    <Button
                      color="bg-blue-600"
                      onClick={() =>
                        getQuestion(
                          question.next_question_id,
                          question.next_number
                        )
                      }
                    >
                      Next Question
                    </Button>
                  ) : (
                    <Button color="bg-blue-300" disabled={true}>
                      Previous Question
                    </Button>
                  )}
                </div>
              </div>
              <div className="bg-yellow-100 w-full md:w-1/3 p-3 rounded shadow-lg">
                <div className="flex justify-between">
                  <Button
                    color="bg-blue-600"
                    onClick={() => doneConfirmation()}
                  >
                    selesaikan ujian
                  </Button>
                  <div className="block text-center">
                    {exam.detail ? (
                      <Timer
                        exam={exam.detail}
                        countDownRef={countDownRef}
                        doneExam={() => doneExam()}
                        refreshTime={question.refresh_time}
                        refresh={refresh}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="block mt-8">
                  <QuestionNumber
                    exam_id={exam?.detail?.exam_id}
                    numbers={exam.numbers}
                    question={question}
                    getQuestion={(question_id, number) =>
                      getQuestion(question_id, number)
                    }
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default QuestionContainer;
