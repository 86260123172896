import React from "react";

const Checkout = ({ invoice }) => {
  return (
    <div className="w-full px-6">
      <h1 className="text-lg font-bold mb-6">Checkout</h1>
      <div className="flex flex-col gap-4">
        <div>
          ID Tagihan:
          <br />
          <strong>{invoice.bill_id}</strong>
        </div>
        <div>
          Via:
          <br />
          <strong>{invoice.channel}</strong>
        </div>
        {invoice.no_va ? (
          <div>
            No VA:
            <br />
            <strong>{invoice.no_va}</strong>
          </div>
        ) : (
          ""
        )}
        {invoice.qris_image ? (
          <div>
            <img
              className="w-1/2 mx-auto"
              src={invoice.qris_image}
              alt="qris"
            />
          </div>
        ) : (
          ""
        )}
        <div>
          Total:
          <br />
          <strong>{invoice.total}</strong>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
