import React from "react";
import Input from "../../Components/Input";
import Select from "../../Components/Select";
import Textarea from "../../Components/Textarea";

const StudentBiodataForm = ({
  records,
  handleRecords,
  handleRecordsOnlyNumber,
  errors,
}) => {
  return (
    <div className="bg-slate-100 mt-8">
      <h2 className="border-b border-blue-300 uppercase mb-3 py-2 px-4 bg-blue-600 text-white">
        biodata siswa
      </h2>
      <div className="p-3">
        <Input
          name="name"
          type="text"
          label="1. Nama Lengkap Peserta Didik"
          description="nama sesuai Dokumen resmi yang berlaku (Akta lahir, KK, atau Ijazah sebelumnya)"
          value={records?.name}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Select
          name="gender"
          type="text"
          label="2. Jenis kelamin"
          options={["Laki - Laki", "Perempuan"]}
          value={records.gender ?? ""}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
        <Input
          name="birthplace"
          type="text"
          label="3. Tempat Lahir"
          description="Tempat lahir peserta didik sesuai dokumen resmi yang berlaku"
          value={records.birthplace ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="birthdate"
          type="date"
          label="4. Tanggal Lahir"
          description="Tanggal lahir peserta didik sesuai dokumen resmi yang berlaku"
          value={records.birthdate ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Textarea
          name="address"
          label="5. Alamat Lengkap Sesuai Domisili"
          rows="4"
          value={records.address ?? ""}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
        <Input
          name="fathername"
          type="text"
          label="6. Nama Ayah Kandung"
          value={records.fathername ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="fatherphone"
          type="text"
          label="7. Nomer Ponsel Ayah"
          value={records.fatherphone ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Input
          name="mothername"
          type="text"
          label="8. Nama Ibu Kandung"
          value={records.mothername ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="motherphone"
          type="text"
          label="9. Nomer Ponsel Ibu"
          value={records.motherphone ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Input
          name="phone"
          type="tel"
          label="10. Nomor Whatsapp Orang Tua"
          description="Wajib diisi untuk pemberitahuan"
          value={records.phone ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Input
          name="fromschool"
          type="text"
          label="11. Sekolah Asal SMP/MTs"
          value={records.fromschool ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Select
          name="interest"
          type="text"
          label="12. Minat Jurusan (*)"
          value={records.interest ?? ""}
          options={["REGULER", "TAHFIDZ"]}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
      </div>
    </div>
  );
};

export default StudentBiodataForm;
