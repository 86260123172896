import React from "react";

const ButtonLogin = (props) => {
  const {
    color = "bg-gradient-to-r from-blue-600 via-slate-800 to-blue-600",
    children,
  } = props;
  return (
    <button
      {...props}
      className={`${color} text-white font-bold capitalize py-4 px-4 rounded-full`}
    >
      {children}
    </button>
  );
};

export default ButtonLogin;
