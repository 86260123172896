import React, { useState } from "react";
import MethodList from "./MethodList";
import Button from "../../Components/Button";
import Checkout from "./Checkout";
import { checkoutAction } from "../../Utils/BillFunct";
import { useSetRecoilState } from "recoil";
import { loadingscreen } from "../../Store";
import Swal from "sweetalert2";

const PaymentCheckout = ({ modal, setModal, bill, getBill }) => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [method, setMethod] = useState({ name: "" });

  const checkout = async () => {
    if (method.name === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Belum memilih metode bayar!",
      });
      return;
    }
    setLoading(true);
    await checkoutAction(bill, method);
    getBill();
    setLoading(false);
  };

  return (
    <div className="bg-slate-800/80 absolute min-h-full w-full flex flex-col justify-center">
      <div className="relative min-h-screen px-4 md:flex md:items-center md:justify-center">
        <div className="fixed inset-x-0 bottom-0 z-50 mx-4 mb-4 rounded-lg bg-white p-4 md:relative md:mx-auto sm:max-w-2xl md:w-2/5">
          <div className="items-center md:flex">
            <div className="mt-4 text-center md:ml-6 md:mt-0 md:text-left">
              {bill.invoice ? (
                <Checkout invoice={bill.invoice} />
              ) : (
                <MethodList bill={bill} method={method} setMethod={setMethod} />
              )}
            </div>
          </div>
          <div
            className={`flex flex-col-reverse ${
              bill.invoice ? "md:flex-col" : "md:flex-row"
            } justify-between w-full mt-6 gap-6`}
          >
            <Button color="bg-red-400" onClick={() => setModal(!modal)}>
              close
            </Button>
            {!bill.invoice ? (
              <Button
                color="bg-green-500"
                onClick={() => {
                  checkout();
                }}
              >
                Bayar
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCheckout;
