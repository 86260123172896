import React from "react";
import ScholarshipFile from "./ScholarshipFile";

const ScholarshipTahfidzForm = ({ type }) => {
  return (
    <div>
      <ScholarshipFile
        file_name={"report_file"}
        label={"Rapot semester 1 sampai 5"}
        type={type}
      />
      <ScholarshipFile
        file_name={"health_file"}
        label={"Surat Kesehatan"}
        type={type}
      />
      <ScholarshipFile
        file_name={"napza_file"}
        label={"Surat Napza"}
        type={type}
      />
      <ScholarshipFile
        file_name={"munaqosyah_file"}
        label={
          "Sertifikat Munaqosyah (melampirkan jumlah hafalan minimal 15 juz)"
        }
        type={type}
      />
      <ScholarshipFile
        file_name={"report_tahfidz_file"}
        label={"Rapot Tahfidz"}
        type={type}
      />
      <ScholarshipFile
        file_name={"mtq_file"}
        label={"Sertifikat Lomba MTQ/MHQ maximal 3"}
        type={type}
      />
    </div>
  );
};

export default ScholarshipTahfidzForm;
