import React from "react";
import Button from "../Components/Button";

const VerifyFail = () => {
  return (
    <div className="bg-red-300 p-8 rounded">
      <div className="w-full mb-8 font-bold">
        E-Mail gagal diverifikasi, silahkan hubungi admin ppdb.
      </div>
      <div className="w-full">
        <a href={process.env.REACT_APP_GUEST_URL} rel="noreferrer">
          <Button color="bg-yellow-600">Back To PPDB Web</Button>
        </a>
      </div>
    </div>
  );
};

export default VerifyFail;
