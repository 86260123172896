import React from "react";
import { Link } from "react-router-dom";
import Button from "../Components/Button";

const VeirfySuccess = () => {
  return (
    <div className="bg-green-400 p-8 rounded font-bold">
      <div className="w-full mb-8">
        E-Mail berhasil diverifikasi, anda dapat login ke dashboard siswa
      </div>
      <div className="w-full">
        <Link to="/">
          <Button>Login</Button>
        </Link>
      </div>
    </div>
  );
};

export default VeirfySuccess;
