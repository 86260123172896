import Swal from "sweetalert2";
import { downloadPdf, post } from "./Axios";

export const getPdf = async (bill) => {
  try {
    downloadPdf(
      `bills/${bill.data.id}/download/pdf`,
      `${bill.data.name}-${bill.data.student.no_register}-PPDB-MUGA3`
    );
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.response.message,
    });
  }
};

export const checkoutAction = async (bill, method) => {
  try {
    let response = await post("/bills/checkout", {
      method: method.method,
      channel: method.code,
      bill_id: bill.id,
    });
    if (response.status === 200) {
      return;
    }
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Gagal melakukan checkout!",
    });
  }
};
