import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { authenticated, loadingscreen } from "../Store";
import { get } from "../Utils/Axios";

const Authenticated = ({ children }) => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const setLoading = useSetRecoilState(loadingscreen);
  const getMe = async () => {
    try {
      setLoading(true);
      const response = await get("/students/me");
      if (response.status === 200) {
        setAuth({ login: true, user: response.data });
      }
      setLoading(false);
    } catch (error) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("lastpath");
      setAuth({ login: false, user: {} });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!auth.login && sessionStorage.getItem("token")) {
      getMe();
    }
    // eslint-disable-next-line
  }, [auth.login]);
  return (
    <div>{auth.login ? children : <Navigate to="/" replace={true} />}</div>
  );
};

export default Authenticated;
