import React, { useEffect, useState } from "react";
import axios from "axios";
import { useRecoilState, useSetRecoilState } from "recoil";
import { assetsconfig, authenticated, loadingscreen } from "../Store";
import { Navigate } from "react-router-dom";
import LoginComponent from "./LoginComponent";
const LoginContainer = () => {
  const setAssets = useSetRecoilState(assetsconfig);
  const [auth, setAuth] = useRecoilState(authenticated);
  const setLoading = useSetRecoilState(loadingscreen);
  const [records, setRecords] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState([]);
  const getAsset = () => {
    setLoading(true);
    axios
      .get(`/templates`)
      .then(function (response) {
        // handle success
        setAssets(response.data);
        setLoading(false);
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
        // console.log(error.message);
      });
  };

  const handleInput = (el) => {
    const change = { ...records };
    const input = el.target;
    change[input.name] = input.value;
    setRecords(change);
  };
  const login = async () => {
    try {
      setLoading(true);
      let response = await axios.post(`/students/login`, records);
      if (response.status === 200) {
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("lastpath", "/dashboards");
        setAuth({ login: true, user: response.data });
      }
      setLoading(false);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);
      setAuth({ login: false, user: {} });
    }
  };
  const handleKey = (event) => {
    if (event.key === "Enter") {
      login();
    }
    return;
  };
  useEffect(() => {
    getAsset();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {}, [auth.login]);
  return (
    <div className="bg-white h-screen">
      {auth.login ? (
        <Navigate to={sessionStorage.getItem("lastpath")} replace={true} />
      ) : (
        <LoginComponent
          handleInput={(e) => handleInput(e)}
          login={() => login()}
          handleKey={(event) => handleKey(event)}
          records={records}
          errors={errors}
        />
      )}
    </div>
  );
};

export default LoginContainer;
