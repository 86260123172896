export default [
  { name: "Pend. Agama Islam", code: "pend_agama_islam" },
  { name: "PPKN", code: "ppkn" },
  { name: "Bahasa Indonesia", code: "bahasa_indonesia" },
  { name: "Matematika", code: "matematika" },
  { name: "IPA", code: "ipa" },
  { name: "IPS", code: "ips" },
  { name: "Bahasa Inggris", code: "bahasa_inggris" },
  { name: "Seni Budaya", code: "seni_budaya" },
  { name: "Penjaskes", code: "penjaskes" },
  { name: "Prakarya", code: "prakarya" },
];
