import React, { Fragment } from "react";

const InputLogin = (props) => {
  const { name, label, description = "", errors } = props;
  return (
    <Fragment>
      <label
        htmlFor={name}
        className="capitalize block ml-4 mb-2 mt-4 font-bold"
      >
        {label} *
      </label>
      <span className="capitalize block text-sm">{description}</span>
      <input
        {...props}
        className="p-2 w-full rounded-full bg-white border-2 border-blue-700"
      />
      {errors[name] ? (
        <div className="w-full bg-red-500 text-white py-2 px-4 mt-2 rounded-full">
          {errors[name]}
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default InputLogin;
