import React from "react";

const Footer = () => {
  return (
    <div className="min-w-screen bg-[#000066] text-white p-3 mt-8 text-center font-bold">
      tif.web.id
    </div>
  );
};

export default Footer;
