import React, { useEffect, useState } from "react";
import Input from "../../Components/Input";
import { useSetRecoilState } from "recoil";
import { loadingscreen } from "../../Store";
import { AiFillCloseCircle, AiFillFilePdf } from "react-icons/ai";
import {
  deleteFileAction,
  getFileAction,
  uploadFileAction,
} from "../../Utils/ScholarshipFunct";

const ScholarshipFile = ({ file_name, label, type }) => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [scholarship, setScholarship] = useState(false);
  const [errors, setErrors] = useState([]);

  const uploadFile = async (el) => {
    setLoading(true);
    await uploadFileAction(el, setScholarship, setErrors, type);
    setLoading(false);
  };

  const deleteFile = async () => {
    setLoading(true);
    await deleteFileAction(file_name, setScholarship, setErrors);
    setLoading(false);
  };

  const getFile = async () => {
    await getFileAction(file_name, setScholarship, setErrors);
  };

  useEffect(() => {
    getFile();
    return () => {};
  }, []);

  return (
    <div className="mt-6">
      {scholarship === false ? (
        <Input
          name={file_name}
          type="file"
          accept="image/jpeg,image/jpg,image/png,application/pdf"
          label={label}
          description="format pdf,JPG tidak lebih dari 2MB"
          errors={errors}
          onChange={(e) => uploadFile(e)}
        />
      ) : (
        <div className="relative">
          <div className="font-bold">{label}</div>
          <span onClick={() => deleteFile()}>
            <AiFillCloseCircle
              className="absolute top-0 right-0 cursor-pointer text-red-400 hover:text-orange-300"
              size={40}
            />
          </span>
          {scholarship.ext === "image" ? (
            <img src={scholarship.file ?? ""} loading="lazy" alt="file" />
          ) : (
            <div className="cursor-pointer">
              <a
                href={scholarship.file}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillFilePdf
                  className="text-orange-500 hover:text-orange-300 mx-auto"
                  size={300}
                />
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ScholarshipFile;
