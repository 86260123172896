import React from "react";
import { Link } from "react-router-dom";
import ButtonLogin from "../Components/ButtonLogin";
import InputLogin from "../Components/InputLogin";

const LoginComponent = ({ handleInput, handleKey, records, errors, login }) => {
  return (
    <div className="flex flex-col md:flex-row h-screen">
      <div
        style={{ backgroundImage: `url(./login-banner.jpg)` }}
        className="bg-cover w-full basis-3/4 rounded-tr-[50px] rounded-br-[50px] drop-shadow-md bg-center"
      >
        <div className="relative h-96 md:h-screen">
          <img
            src="./login-top-title.png"
            alt="Login Top Title"
            className="absolute left-4 top-4"
          />
          <img
            src="./login-under-title.png"
            alt="Login Under Title"
            className="absolute left-0 bottom-0"
          />
        </div>
      </div>
      <div className="md:basis-1/3 p-4">
        <div className="w-full">
          <img src="./login-icon.png" alt="Login Icon" className="mx-auto" />
        </div>
        <div className="w-full mt-4">
          <h1 className="text-2xl font-bold text-center capitalize text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-slate-700 to-blue-600">
            login your account
          </h1>
        </div>
        <div className="flex flex-col">
          <InputLogin
            type="email"
            name="email"
            label="E-Mail"
            value={records.name}
            onChange={(e) => handleInput(e)}
            errors={errors}
          />
          <InputLogin
            type="password"
            name="password"
            label="Password"
            value={records.password}
            onChange={(e) => handleInput(e)}
            onKeyDown={(event) => handleKey(event)}
            errors={errors}
          />
        </div>
        <div className="flex justify-end mt-4">
          <Link
            to={"/forgot"}
            className="capitalize font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-slate-800 to-blue-600"
          >
            lupa password
          </Link>
        </div>
        <div className="flex flex-col gap-6 mt-8">
          <ButtonLogin onClick={() => login()}>login</ButtonLogin>
          <div className="text-center">
            Jika anda belum memiliki akun pendaftaran. maka daftar terlebih
            dahulu untuk memiliki akun
          </div>
          <a
            href={`${process.env.REACT_APP_GUEST_URL}/units/sma-3/daftar`}
            target="_blank"
            rel="noopener noreferrer"
            className="capitalize border-2 border-blue-700 rounded-full p-4 text-center font-bold"
          >
            daftar
          </a>
          <div>©tif.web.id {new Date().getFullYear()} All rights reserved.</div>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
