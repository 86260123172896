import React from "react";
import { FaArrowCircleDown } from "react-icons/fa";

const ArrowDownComponent = (props) => {
  const { step, cond } = props;
  const show = (step, cond) => {
    if (step === cond) {
      return true;
    }
    return false;
  };
  return (
    <div>
      {show(step, cond) ? (
        <FaArrowCircleDown
          {...props}
          className="animate__animated animate__bounce animate__infinite mb-4 mx-auto absolute bottom-32 m-auto left-0 right-0"
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ArrowDownComponent;
