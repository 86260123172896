import React from "react";
import {
  BsFillPencilFill,
  BsFillPersonBadgeFill,
  BsPersonFill,
  BsReceiptCutoff,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authenticated } from "../Store";

const DashboardTopBox = ({ bills, exam, quran }) => {
  const auth = useRecoilValue(authenticated);
  return (
    <div className="flex flex-col md:flex-row md:flex-wrap lg:flex-nowrap justify-between md:justify-center gap-4">
      <div className="bg-gray-100 shadow-md w-full md:w-2/5 lg:w-1/2 rounded-2xl p-3">
        {auth.user.photo ? (
          <img
            className="w-48 h-60 mx-auto rounded-md"
            src={auth.user.photo}
            alt="profile-foto"
          />
        ) : (
          <BsPersonFill className="mx-auto" size={200} />
        )}
        <div className="pb-5 text-center mt-4">
          <span className="bg-green-300 rounded p-1 px-2 font-bold">
            {auth.user.no_register}
          </span>
        </div>
      </div>
      <div className="bg-gray-100 rounded-2xl shadow-md w-full md:w-2/4 lg:w-1/2">
        <div className="flex items-center">
          <div>
            <BsFillPersonBadgeFill size={50} className="p-2" />
          </div>
          <div className="uppercase">status</div>
        </div>
        <div className="uppercase text-center text-lg p-3 py-8">
          <span
            className={`${
              auth.user.status === "diterima" ? "bg-green-500" : "bg-slate-500"
            } p-3 px-6 rounded text-white font-bold`}
          >
            {auth.user.status}
          </span>
        </div>
      </div>
      <div className="bg-gray-100 rounded-2xl shadow-md w-full md:w-2/4 lg:w-1/2">
        <div className="flex items-center">
          <div>
            <BsReceiptCutoff size={50} className="p-2" />
          </div>
          <div className="uppercase">tagihan</div>
        </div>
        <div role="list" className="p-2 divide-y divide-slate-300">
          {bills.map((bill) => (
            <div
              key={bill.id}
              className="flex py-2 first:pt-0 last:pb-0 cursor-pointer hover:bg-green-300"
            >
              <Link to={`/bills/${bill.id}`} className="ml-3 overflow-hidden">
                <p className="text-sm font-medium text-slate-900">
                  {bill.name}
                </p>
                <p className="text-sm text-slate-500 truncate">
                  {bill.created_at}
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-gray-100 rounded-2xl shadow-md w-full md:w-2/5 lg:w-1/2">
        <div className="flex items-center">
          <div>
            <BsFillPencilFill size={50} className="p-2" />
          </div>
          <div className="uppercase">tes masuk</div>
        </div>
        <div role="list" className="p-2 divide-y divide-slate-300">
          {exam !== null ? (
            <Link
              to={`/students/exam`}
              className="flex py-2 first:pt-0 last:pb-0 cursor-pointer hover:bg-green-300"
            >
              <div className="ml-3 overflow-hidden">
                <p className="text-sm font-medium text-slate-900">
                  {exam.name}
                </p>
                <p className="text-sm text-slate-500 truncate">{exam.date}</p>
              </div>
            </Link>
          ) : (
            ""
          )}
          {quran !== null ? (
            <Link
              to={`/students/quran`}
              className="flex py-2 first:pt-0 last:pb-0 cursor-pointer hover:bg-green-300"
            >
              <div className="ml-3 overflow-hidden">
                <p className="text-sm font-medium text-slate-900">
                  {quran.name}
                </p>
                <p className="text-sm text-slate-500 truncate">{quran.date}</p>
              </div>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardTopBox;
