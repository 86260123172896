import lessons from "../Store/lessons";
import { del, get, post } from "./Axios";

export const scoreInput = (el, index, lesson, scholarship, setScholarship) => {
  let updates = { ...scholarship.academics };
  const input_name = el.target.name;
  let input = el.target.value;
  if (input !== "") {
    input = parseFloat(input);
  }
  updates[index] = {
    ...updates[index],
    name: lesson.name,
    code: lesson.code,
    [input_name]: input,
  };
  let sum_period = { ...scholarship?.sum_period };
  let avg_period = { ...scholarship?.avg_period };
  if (input !== "") {
    const sum = sumPeriodScore(updates, input_name);
    sum_period[`sum_${input_name}`] = sum;
    avg_period[`avg_${input_name}`] = sum / lessons.length;
  }
  console.log(scholarship);
  setScholarship({
    ...scholarship,
    academics: updates,
    avg_period,
    sum_period,
  });
};

export const sumPeriodScore = (updates, input_name) => {
  let scores = [];
  lessons.forEach((less, lesson_index) => {
    scores.push(periodScore(updates, lesson_index, input_name));
  });
  const sum = scores.reduce((a, b) => a + b, 0);
  // console.log(scores);
  return sum;
};

const periodScore = (updates, lesson_index, input_name) => {
  let score = 0;
  if (updates[lesson_index]) {
    score = updates[lesson_index][input_name]
      ? parseFloat(updates[lesson_index][input_name])
      : 0;
  }
  // console.log(scholarship[lesson]);
  return score;
};

export const uploadFileAction = async (el, setFile, setErrors, type) => {
  const data = new FormData();
  data.append("type", type);
  data.append("inputname", el.target.name);
  data.append(el.target.name, el.target.files[0]);
  try {
    const upload = await post(`students/biodata/scholarship/upload`, data);
    if (upload.status === 200) {
      // console.log(upload.data);
      setFile(upload.data);
    }
  } catch (error) {
    setErrors(error.response.data);
  }
};

export const deleteFileAction = async (column, setFile, setErrors) => {
  try {
    let response = await del(`students/biodata/scholarship/${column}/delete`);
    if (response.status === 200) {
      setFile(false);
      setErrors([]);
    }
  } catch (e) {
    setErrors(e.response);
  }
};

export const getFileAction = async (column, setFile, setErrors) => {
  try {
    let response = await get(`students/biodata/scholarship/${column}`);
    if (response.status === 200) {
      setFile(response.data);
    }
  } catch (error) {
    setErrors(error.response.data);
  }
};
