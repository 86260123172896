import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Button from "../Components/Button";
import { loadingscreen } from "../Store";
import ForgotComponent from "./ForgotComponent";

const ForgotContainer = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [mailsend, setMailsend] = useState({ forgot: false });
  const [records, setRecords] = useState({});
  const [errors, setErrors] = useState([]);
  const handleInput = (el) => {
    const change = { ...records };
    const input = el.target;
    change[input.name] = input.value;
    setRecords(change);
  };
  const forgot = async () => {
    try {
      setLoading(true);
      let response = await axios.post(`/students/forgot`, records);
      if (response.status === 200) {
        setMailsend({
          forgot: true,
          success: true,
          message: response.data.message,
        });
      }
      setErrors([]);
      setLoading(false);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);
    }
  };
  return (
    <div className="w-full bg-slate-500 h-screen p-2">
      {mailsend.forgot ? (
        <div className="flex flex-col justify-center items-center">
          <div className="bg-green-400 w-1/2 rounded text-center p-4 px-6 mb-6">
            {mailsend.message}
          </div>
          <Link to="/">
            <Button color="bg-orange-500">Login</Button>
          </Link>
        </div>
      ) : (
        <ForgotComponent
          handleInput={(e) => handleInput(e)}
          forgot={() => forgot()}
          records={records}
          errors={errors}
        />
      )}
      {errors.message ? (
        <div className="bg-red-500 p-4 px-6 mt-8 w-1/2 rounded mx-auto text-center text-white">
          {errors.message}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ForgotContainer;
