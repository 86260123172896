import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import VeirfySuccess from "./VeirfySuccess";
import VerifyFail from "./VerifyFail";
import { loadingscreen } from "../Store";

const VerifyContainer = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const { token } = useParams();
  const [verify, setVerify] = useState(false);
  const getData = () => {
    setLoading(true);
    axios
      .get(`/register/${token}/verify`)
      .then(function (response) {
        // handle success
        setVerify(true);
        setLoading(false);
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        setVerify(false);
        setLoading(false);
        // console.log(error);
      });
  };

  useEffect(() => {
    // getData();
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <div
      style={{ backgroundImage: `url(./login-banner.jpg)` }}
      className="h-screen bg-cover bg-center"
    >
      <div className="p-8 text-center">
        {verify ? <VeirfySuccess /> : <VerifyFail />}
      </div>
    </div>
  );
};

export default VerifyContainer;
