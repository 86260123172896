import React from "react";

const Button = (props) => {
  const { color = "bg-blue-500", children } = props;
  return (
    <button
      {...props}
      className={`${color} text-white uppercase py-2 px-4 rounded`}
    >
      {children}
    </button>
  );
};

export default Button;
