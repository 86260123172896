import React from "react";
import Button from "../Components/Button";

const QuranDetail = ({ data }) => {
  return (
    <div className="bg-slate-100">
      <h2 className="border-b border-blue-300 uppercase mb-3 py-2 px-4 bg-blue-600 text-white">
        {data?.name} {data?.quran_date}
      </h2>
      <div className="p-3">
        <div className="bg-slate-200 p-4 mb-3 rounded">
          Nama Pemetaan :
          <br />
          {data?.name}
        </div>
        <div className="bg-slate-200 p-4 mb-3 rounded">
          Tanggal Pemetaan :
          <br />
          {data?.quran_date}
        </div>
        <div className="bg-slate-200 p-4 mb-3 rounded">
          Jam Pemetaan :
          <br />
          {data?.quran_time}
        </div>
        <div className="bg-slate-200 p-4 mb-3 rounded">
          Deskripsi :
          <br />
          {data?.description}
        </div>
        {/* <div className="mt-8 mb-3 text-center">
          <a href={data?.zoom_url} target="_blank" rel="noopener noreferrer">
            <Button>zoom link</Button>
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default QuranDetail;
