import React from "react";
import Input from "../../Components/Input";

const StudentDetailedForm = ({
  records,
  handleRecords,
  handleRecordsOnlyNumber,
  errors,
}) => {
  return (
    <div className="bg-slate-100 mt-8">
      <h2 className="border-b border-blue-300 uppercase mb-3 py-2 px-4 bg-blue-600 text-white">
        DATA RINCI PESERTA DIDIK
      </h2>
      <div className="p-3">
        <div>
          <Input
            name="bodyheight"
            type="text"
            label="37. Tinggi Badan (*)"
            value={records.bodyheight ?? ""}
            errors={errors}
            onChange={(e) => handleRecordsOnlyNumber(e)}
          />
          <Input
            name="bodyweight"
            type="text"
            label="38. Berat Badan (*)"
            value={records.bodyweight ?? ""}
            errors={errors}
            onChange={(e) => handleRecordsOnlyNumber(e)}
          />
          <Input
            name="headcircle"
            type="text"
            label="39. Lingkar Kepala (*)"
            value={records.headcircle ?? ""}
            errors={errors}
            onChange={(e) => handleRecordsOnlyNumber(e)}
          />
          <Input
            name="bloodclass"
            type="text"
            label="40. Golongan Darah (*)"
            value={records.bloodclass ?? ""}
            errors={errors}
            onChange={(e) => handleRecords(e)}
          />
          <Input
            name="schooldistance"
            type="text"
            label="41. Jarak Tempat Tinggal Ke Sekolah (*)"
            description="sebutkan dalam Kilometer (KM)"
            value={records.schooldistance ?? ""}
            errors={errors}
            onChange={(e) => handleRecords(e)}
          />
          <Input
            name="timetoschool"
            type="text"
            label="42. Waktu Tempuh ke Sekolah (jam/menit) (*)"
            value={records.timetoschool ?? ""}
            errors={errors}
            onChange={(e) => handleRecords(e)}
          />
          <Input
            name="fromschool"
            type="text"
            label="43. Sekolah Asal SMP/MTs (*)"
            value={records.fromschool ?? ""}
            errors={errors}
            onChange={(e) => handleRecords(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentDetailedForm;
