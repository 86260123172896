import { atom } from "recoil";
export const assetsconfig = atom({
  key: "assets",
  default: false,
});
export const authenticated = atom({
  key: "auth",
  default: { login: false, user: {} },
});

export const dashboards = atom({
  key: "dashboard",
  default: false,
});

export const scholarships = atom({
  key: "scholarship",
  default: { name: "", academics: [] },
});

export const loadingscreen = atom({
  key: "loading",
  default: false,
});
