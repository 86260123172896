import React from "react";
import { Link } from "react-router-dom";
import Button from "../Components/Button";
import Input from "../Components/Input";

const ResetComponent = ({ handleInput, reset, records, errors }) => {
  const handleKey = (event) => {
    if (event.key === "Enter") {
      reset();
    }
    return;
  };
  return (
    <div className="bg-gray-200 p-4 w-full md:w-10/12 lg:w-1/2 h-auto rounded shadow-md mx-auto">
      <div className="w-full">
        <h1 className="uppercase text-4xl">ppdb reset password</h1>
      </div>
      <div className="w-full mt-4">
        <Input
          type="password"
          name="password"
          label="Password"
          value={records.password ?? ""}
          onChange={(e) => handleInput(e)}
          onKeyDown={handleKey}
          errors={errors}
        />
      </div>
      <div className="w-full mt-4">
        <Input
          type="password"
          name="password_confirm"
          label="Password Confirm"
          value={records.password_confirm ?? ""}
          onChange={(e) => handleInput(e)}
          onKeyDown={handleKey}
          errors={errors}
        />
      </div>
      <div className="flex justify-between flex-col md:flex-row gap-4 mt-8">
        <Link to={"/"}>
          <Button color="bg-orange-500 w-full">login</Button>
        </Link>
        <Button onClick={() => reset()}>update</Button>
      </div>
    </div>
  );
};

export default ResetComponent;
