import React from "react";
import {
  FaAddressBook,
  FaBookOpen,
  FaBuffer,
  FaCreditCard,
  FaFileSignature,
  FaListUl,
  FaMarker,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import ArrowDownComponent from "../Components/ArrowDownComponent";
import { authenticated } from "../Store";
import StatusBoxStudent from "./StatusBoxStudent";

const DashboardStepBox = ({
  satCount,
  steps,
  all_steps,
  formulir,
  exam,
  quran,
  sat,
  scholarship_status,
}) => {
  const auth = useRecoilValue(authenticated);
  const statusColorBox = (status) => {
    if (status === "diterima") {
      return "bg-green-300";
    } else if (status === "tidak diterima") {
      return "bg-red-300";
    } else if (status === "mengundurkan diri") {
      return "bg-yellow-300";
    } else {
      return "bg-gray-100";
    }
  };
  const scholarshipColorBox = (status) => {
    if (status === "mendapat") {
      return "bg-green-300";
    } else if (status === "tidak dapat") {
      return "bg-yellow-300";
    } else {
      return "bg-gray-100";
    }
  };
  const arrowBox = (scholar_step, normal_step) => {
    if (scholarship_status) {
      return scholar_step;
    } else {
      return normal_step;
    }
  };
  return (
    <div className="flex justify-center flex-wrap xl:flex-nowrap gap-y-24 gap-4 mt-20">
      <Link
        to={"/biodatas"}
        className={`${
          steps.biodata ? "bg-green-300" : "bg-gray-100"
        } rounded-2xl shadow-md w-40 p-4 relative transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-300 duration-300`}
      >
        <ArrowDownComponent size={40} step={all_steps} cond={"00000000"} />
        <FaAddressBook size={40} className="mx-auto mb-4" />
        <div className="uppercase text-center">isi formulir</div>
      </Link>
      <Link
        to={`${formulir !== null ? `/bills/${formulir}` : "#"}`}
        className={`${
          steps.form_pay ? "bg-green-300" : "bg-gray-100"
        } rounded-2xl shadow-md w-40 p-4 relative transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-300 duration-300`}
      >
        <ArrowDownComponent size={40} step={all_steps} cond={"10000000"} />
        <FaListUl size={40} className="mx-auto mb-4" />
        <div className="uppercase text-center wrapper">
          pembayaran
          <br />
          formulir
        </div>
      </Link>
      {scholarship_status ? (
        <div
          className={`${scholarshipColorBox(
            scholarship_status
          )} rounded-2xl shadow-md w-40 p-4 relative transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-300 duration-300`}
        >
          <ArrowDownComponent size={40} step={all_steps} cond={"11000000"} />
          <FaFileSignature size={40} className="mx-auto mb-4" />
          <div className="uppercase text-center wrapper">
            {scholarship_status} Beasiswa
          </div>
        </div>
      ) : (
        ""
      )}
      <Link
        to={`${exam !== null ? `/students/exam` : "#"}`}
        className={`${
          steps.online_test ? "bg-green-300" : "bg-gray-100"
        } rounded-2xl shadow-md w-40 p-4 relative transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-300 duration-300`}
      >
        <ArrowDownComponent
          size={40}
          step={all_steps}
          cond={arrowBox("11100000", "11000000")}
        />
        <FaMarker size={40} className="mx-auto mb-4" />
        <div className="uppercase text-center">
          pemetaan masuk dan wawancara
        </div>
      </Link>
      <Link
        to={`${quran !== null ? `/students/quran` : "#"}`}
        className={`${
          steps.quran_test ? "bg-green-300" : "bg-gray-100"
        } rounded-2xl shadow-md w-40 p-4 relative transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-300 duration-300`}
      >
        <ArrowDownComponent
          size={40}
          step={all_steps}
          cond={arrowBox("11110000", "11010000")}
        />
        <FaBookOpen size={40} className="mx-auto mb-4" />
        <div className="uppercase text-center">pemetaan qur'an</div>
      </Link>
      <div
        className={`${statusColorBox(
          auth.user.status
        )} rounded-2xl shadow-md w-40 p-4 relative transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-300 duration-300`}
      >
        <ArrowDownComponent
          size={40}
          step={all_steps}
          cond={arrowBox("11111000", "11011000")}
        />
        <StatusBoxStudent status={auth.user.status} />
        <div className="uppercase text-center">{auth.user.status}</div>
      </div>
      <Link
        to={`${sat !== null ? `/bills/${sat}` : "#"}`}
        className={`${
          steps.sat_pay ? "bg-green-300" : "bg-gray-100"
        } rounded-2xl shadow-md w-40 p-4 relative transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-300 duration-300`}
      >
        <ArrowDownComponent
          size={40}
          step={all_steps}
          cond={arrowBox("11111100", "11011100")}
        />
        {satCount > 0 ? (
          <span className="bg-red-500 text-white py-1 px-4 rounded absolute left-32 bottom-32 font-bold">
            {satCount}
          </span>
        ) : (
          ""
        )}
        <FaCreditCard size={40} className="mx-auto mb-4" />
        <div className="uppercase text-center">pembayaran sat</div>
      </Link>
      <Link
        to={`${
          auth.user.status === "diterima" ? `/students/re/register` : "#"
        }`}
        className={`${
          steps.re_register ? "bg-green-300" : "bg-gray-100"
        } rounded-2xl shadow-md w-40 p-4 relative transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-300 duration-300`}
      >
        {auth.user.status === "diterima" ? (
          <ArrowDownComponent
            size={40}
            step={all_steps}
            cond={arrowBox("11111110", "11011110")}
          />
        ) : (
          ""
        )}
        <FaBuffer size={40} className="mx-auto mb-4" />
        <div className="uppercase text-center">isi kelengkapan biodata</div>
      </Link>
    </div>
  );
};

export default DashboardStepBox;
