import React, { Fragment } from "react";

const Input = (props) => {
  const { name, label, description = "", errors, decor = "" } = props;
  return (
    <Fragment>
      <label htmlFor={name} className="capitalize block mb-1 mt-4">
        {label}
      </label>
      <span className="capitalize block mb-4 text-sm">{description}</span>
      <input
        {...props}
        className={`p-2 w-full rounded-full shadow-md bg-white ${decor}`}
      />
      {errors[name] ? (
        <div className="w-full bg-red-500 text-white py-2 px-4 mt-3">
          {errors[name]}
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Input;
