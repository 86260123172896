import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { AiFillCloseCircle, AiFillFilePdf } from "react-icons/ai";
import { useSetRecoilState } from "recoil";
import Input from "../Components/Input";
import { loadingscreen } from "../Store";
import { del, downloadPdf, post } from "../Utils/Axios";

const StudentFileForm = ({ records, setRecords, errors, setErrors }) => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [certificate, setCertificate] = useState(false);
  const [reportcard, setReportcard] = useState(false);
  const [deedbirthfile, setDeedbirthfile] = useState(false);
  const [familycardfile, setFamilycardfile] = useState(false);
  const [certonefile, setCertonefile] = useState(false);
  const [certtwofile, setCerttwofile] = useState(false);
  const updateRecordValue = (name, value) => {
    const change = { ...records };
    change[name] = value;
    setRecords(change);
  };
  const uploadFile = async (el) => {
    const data = new FormData();
    data.append("inputname", el.target.name);
    data.append(el.target.name, el.target.files[0]);
    try {
      setLoading(true);
      const upload = await post(`students/biodata/upload`, data);
      if (upload.status === 200) {
        // console.log(upload.data);
        const file = { location: upload.data.location, ext: upload.data.ext };
        updateRecordValue(el.target.name, file);
        if (upload.data.ext === "image") {
          getImage(el.target.name, upload.data.location, upload.data.ext);
        }
        setLoading(false);
      }
    } catch (error) {
      setErrors(error.response.data);
      setLoading(false);
    }
  };
  const deleteFile = async (column) => {
    try {
      setLoading(true);
      let response = await del(`/students/biodata/${column}/delete`);
      if (response.status === 200) {
        const file = { location: "", ext: false };
        updateRecordValue(column, file);
        changeImages(column, false);
        setErrors([]);
      }
      setLoading(false);
    } catch (e) {
      setErrors(e.response);
      setLoading(false);
    }
  };
  const changeImages = (name, value) => {
    if ("certificate" === name) {
      setCertificate(value);
    } else if ("reportcard" === name) {
      setReportcard(value);
    } else if ("deedbirthfile" === name) {
      setDeedbirthfile(value);
    } else if ("familycardfile" === name) {
      setFamilycardfile(value);
    } else if ("certonefile" === name) {
      setCertonefile(value);
    } else if ("certtwofile" === name) {
      setCerttwofile(value);
    }
  };
  const getImage = (name, url, type) => {
    if (type === "image") {
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          responseType: "arraybuffer",
        })
        .then((res) => {
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          // return base64;
          changeImages(name, base64);
        });
    }
  };
  const getAllImage = () => {
    setTimeout(() => {
      getImage(
        "certificate",
        records.certificate.location,
        records.certificate.ext
      );
    }, 1000);
    setTimeout(() => {
      getImage(
        "reportcard",
        records.reportcard.location,
        records.reportcard.ext
      );
    }, 2000);
    setTimeout(() => {
      getImage(
        "deedbirthfile",
        records.deedbirthfile.location,
        records.deedbirthfile.ext
      );
    }, 3000);
    setTimeout(() => {
      getImage(
        "familycardfile",
        records.familycardfile.location,
        records.familycardfile.ext
      );
    }, 4000);
    setTimeout(() => {
      getImage(
        "certonefile",
        records.certonefile.location,
        records.certonefile.ext
      );
    }, 5000);
    setTimeout(() => {
      getImage(
        "certtwofile",
        records.certtwofile.location,
        records.certtwofile.ext
      );
    }, 6000);
  };
  useEffect(() => {
    getAllImage();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-slate-100 mt-8">
      <h2 className="border-b border-blue-300 uppercase mb-3 py-2 px-4 bg-blue-600 text-white">
        FILE PESERTA DIDIK
      </h2>
      <div className="p-3">
        <div>
          {records.photo.location ? (
            <div className="mt-4">
              <label className="mb-4 block">44. Foto 3x4 (*)</label>
              <div className="relative">
                <span onClick={() => deleteFile("photo")}>
                  <AiFillCloseCircle
                    className="absolute top-0 right-0 cursor-pointer text-red-400 hover:text-orange-300"
                    size={40}
                  />
                </span>
                {records.photo.location ? (
                  <img
                    src={records.photo.location ?? ""}
                    loading="lazy"
                    alt="Foto profil"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <Input
              accept="image/jpeg,image/jpg,image/png"
              name="photo"
              type="file"
              label="44. Foto 3x4 (*)"
              description="format JPG tidak lebih dari 2MB"
              errors={errors}
              onChange={(e) => uploadFile(e)}
            />
          )}
        </div>
        <div>
          {records.certificate.ext ? (
            <div className="mt-4">
              <label className="mb-4 block">45. Ijazah</label>
              <div className="relative">
                <span onClick={() => deleteFile("certificate")}>
                  <AiFillCloseCircle
                    className="absolute top-0 right-0 cursor-pointer text-red-400 hover:text-orange-300"
                    size={40}
                  />
                </span>
                {records.certificate.ext === "image" ? (
                  <Fragment>
                    {certificate ? (
                      <img
                        src={`data:image/*;base64,${certificate}`}
                        loading="lazy"
                        alt="Ijazah SMP"
                      />
                    ) : (
                      ""
                    )}
                  </Fragment>
                ) : (
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      downloadPdf(
                        records.certificate.location,
                        "ijazah-smp.pdf"
                      )
                    }
                  >
                    <AiFillFilePdf
                      className="text-orange-500 hover:text-orange-300 mx-auto"
                      size={300}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Input
              name="certificate"
              type="file"
              accept="image/jpeg,image/jpg,image/png,application/pdf"
              label="45. Ijazah"
              description="Jika belum ada izasa dan SKL bisa dikosongkan, format pdf,JPG tidak lebih dari 2MB"
              errors={errors}
              onChange={(e) => uploadFile(e)}
            />
          )}
        </div>
        <div>
          {records.reportcard.ext ? (
            <div className="mt-4">
              <label className="mb-4 block">46. Rapot semester 5 kelas 9</label>
              <div className="relative">
                <span onClick={() => deleteFile("reportcard")}>
                  <AiFillCloseCircle
                    className="absolute top-0 right-0 cursor-pointer text-red-400 hover:text-orange-300"
                    size={40}
                  />
                </span>
                {records.reportcard.ext === "image" ? (
                  <Fragment>
                    {reportcard ? (
                      <img
                        src={`data:image/*;base64,${reportcard}`}
                        loading="lazy"
                        alt="Rapot Sekolah"
                      />
                    ) : (
                      ""
                    )}
                  </Fragment>
                ) : (
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      downloadPdf(records.reportcard.location, "rapot-smp.pdf")
                    }
                  >
                    <AiFillFilePdf
                      className="text-orange-500 hover:text-orange-300 mx-auto"
                      size={300}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Input
              name="reportcard"
              type="file"
              accept="image/jpeg,image/jpg,image/png,application/pdf"
              label="46. Rapot semester 5 kelas 9"
              description="format pdf,JPG tidak lebih dari 2MB"
              errors={errors}
              onChange={(e) => uploadFile(e)}
            />
          )}
        </div>
        <div>
          {records.deedbirthfile.ext ? (
            <div className="mt-4">
              <label className="mb-4 block">47. Akta Lahir (*)</label>
              <div className="relative">
                <span onClick={() => deleteFile("deedbirthfile")}>
                  <AiFillCloseCircle
                    className="absolute top-0 right-0 cursor-pointer text-red-400 hover:text-orange-300"
                    size={40}
                  />
                </span>
                {records.deedbirthfile.ext === "image" ? (
                  <Fragment>
                    {deedbirthfile ? (
                      <img
                        src={`data:image/*;base64,${deedbirthfile}`}
                        loading="lazy"
                        alt="deedbirthfile"
                      />
                    ) : (
                      ""
                    )}
                  </Fragment>
                ) : (
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      downloadPdf(
                        records.deedbirthfile.location,
                        "akta-lahir.pdf"
                      )
                    }
                  >
                    <AiFillFilePdf
                      className="text-orange-500 hover:text-orange-300 mx-auto"
                      size={300}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Input
              accept="image/jpeg,image/jpg,image/png,application/pdf"
              name="deedbirthfile"
              type="file"
              label="47. Upload Akte Lahir (*)"
              description="format pdf,JPG tidak lebih dari 2MB"
              errors={errors}
              onChange={(e) => uploadFile(e)}
            />
          )}
        </div>
        <div>
          {records.familycardfile.ext ? (
            <div className="mt-4">
              <label className="mb-4 block">48. Kartu Keluarga (KK) (*)</label>
              <div className="relative">
                <span onClick={() => deleteFile("familycardfile")}>
                  <AiFillCloseCircle
                    className="absolute top-0 right-0 cursor-pointer text-red-400 hover:text-orange-300"
                    size={40}
                  />
                </span>
                {records.familycardfile.ext === "image" ? (
                  <Fragment>
                    {familycardfile ? (
                      <img
                        src={`data:image/*;base64,${familycardfile}`}
                        loading="lazy"
                        alt="familycardfile"
                      />
                    ) : (
                      ""
                    )}
                  </Fragment>
                ) : (
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      downloadPdf(
                        records.familycardfile.location,
                        "kartu-keluarga.pdf"
                      )
                    }
                  >
                    <AiFillFilePdf
                      className="text-orange-500 hover:text-orange-300 mx-auto"
                      size={300}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Input
              accept="image/jpeg,image/jpg,image/png,application/pdf"
              name="familycardfile"
              type="file"
              label="48. Upload Kartu Keluarga (KK) (*)"
              description="format pdf,JPG tidak lebih dari 2MB"
              errors={errors}
              onChange={(e) => uploadFile(e)}
            />
          )}
        </div>
        <div>
          {records.certonefile.ext ? (
            <div className="mt-4">
              <label className="mb-4 block">
                49. Sertifikat prestasi nasional/internasional 1, maksimal tahun
                2020 kedepan (jika ada)
              </label>
              <div className="relative">
                <span onClick={() => deleteFile("certonefile")}>
                  <AiFillCloseCircle
                    className="absolute top-0 right-0 cursor-pointer text-red-400 hover:text-orange-300"
                    size={40}
                  />
                </span>
                {records.certonefile.ext === "image" ? (
                  <Fragment>
                    {certonefile ? (
                      <img
                        src={`data:image/*;base64,${certonefile ?? ""}`}
                        loading="lazy"
                        alt="Sertifikat 1"
                      />
                    ) : (
                      ""
                    )}
                  </Fragment>
                ) : (
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      downloadPdf(
                        records.certonefile.location,
                        "sertifikat-prestasi-1.pdf"
                      )
                    }
                  >
                    <AiFillFilePdf
                      className="text-orange-500 hover:text-orange-300 mx-auto"
                      size={300}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Input
              name="certonefile"
              type="file"
              accept="image/jpeg,image/jpg,image/png,application/pdf"
              label="49. sertifikat prestasi nasional/internasional 1, maksimal tahun 2020 kedepan (jika ada)"
              description="format pdf,JPG tidak lebih dari 2MB"
              errors={errors}
              onChange={(e) => uploadFile(e)}
            />
          )}
        </div>
        <div>
          {records.certtwofile.ext ? (
            <div className="mt-4">
              <label className="mb-4 block">
                50. Sertifikat prestasi nasional/internasional 2 maksimal tahun
                2020 kedepan (jika ada)
              </label>
              <div className="relative">
                <span onClick={() => deleteFile("certtwofile")}>
                  <AiFillCloseCircle
                    className="absolute top-0 right-0 cursor-pointer text-red-400 hover:text-orange-300"
                    size={40}
                  />
                </span>
                {records.certtwofile.ext === "image" ? (
                  <Fragment>
                    {certtwofile ? (
                      <img
                        src={`data:image/*;base64,${certtwofile ?? ""}`}
                        loading="lazy"
                        alt="Sertifikat 2"
                      />
                    ) : (
                      ""
                    )}
                  </Fragment>
                ) : (
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      downloadPdf(
                        records.certtwofile.location,
                        "sertifikat-prestasi-2.pdf"
                      )
                    }
                  >
                    <AiFillFilePdf
                      className="text-orange-500 hover:text-orange-300 mx-auto"
                      size={300}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Input
              name="certtwofile"
              type="file"
              accept="image/jpeg,image/jpg,image/png,application/pdf"
              label="50. sertifikat prestasi nasional/internasional 2 maksimal tahun 2020 kedepan (jika
                ada)"
              description="format pdf,JPG tidak lebih dari 2MB"
              errors={errors}
              onChange={(e) => uploadFile(e)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentFileForm;
