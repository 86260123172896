import React from "react";
import lessons from "../../Store/lessons";
import Input from "../../Components/Input";
import { scoreInput } from "../../Utils/ScholarshipFunct";
import { useRecoilState } from "recoil";
import { scholarships } from "../../Store";

const ScoreForm = () => {
  const [scholarship, setScholarship] = useRecoilState(scholarships);
  const handleInput = (el, index, lesson) => {
    scoreInput(el, index, lesson, scholarship, setScholarship);
  };
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <table className="min-w-full text-left text-sm">
          <thead className="border-b font-medium dark:border-neutral-500">
            <tr>
              <th rowSpan={2} scope="col" className="px-6 py-4 bg-slate-300">
                #
              </th>
              <th rowSpan={2} scope="col" className="px-6 py-4 bg-slate-300">
                Mata Pelajaran
              </th>
              <th
                colSpan={5}
                scope="col"
                className="px-6 py-4 text-center bg-slate-500 text-white"
              >
                Nilai Pengetahuan
              </th>
            </tr>
            <tr>
              <th scope="col" className="px-6 py-4 bg-slate-400 text-center">
                Semester 1
              </th>
              <th scope="col" className="px-6 py-4 bg-slate-400 text-center">
                Semester 2
              </th>
              <th scope="col" className="px-6 py-4 bg-slate-400 text-center">
                Semester 3
              </th>
              <th scope="col" className="px-6 py-4 bg-slate-400 text-center">
                Semester 4
              </th>
              <th scope="col" className="px-6 py-4 bg-slate-400 text-center">
                Semester 5
              </th>
            </tr>
          </thead>
          <tbody>
            {lessons.map((lesson, index) => (
              <tr key={index} className="border-b dark:border-neutral-500">
                <td className="whitespace-nowrap px-6 py-4 font-medium">
                  {index + 1}
                </td>
                <td className="whitespace-nowrap px-6 py-4">{lesson.name}</td>
                <td className="whitespace-nowrap px-6 py-4">
                  <Input
                    name="semester_1"
                    type="text"
                    label=""
                    description=""
                    value={scholarship?.academics?.[index]?.semester_1 ?? ""}
                    errors={{}}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/\D+/g, ""))
                    }
                    onChange={(el) => {
                      handleInput(el, index, lesson);
                    }}
                    decor={"text-center"}
                  />
                </td>
                <td className="whitespace-nowrap px-6 py-4">
                  <Input
                    name="semester_2"
                    type="text"
                    label=""
                    description=""
                    value={scholarship?.academics?.[index]?.semester_2 ?? ""}
                    errors={{}}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/\D+/g, ""))
                    }
                    onChange={(el) => {
                      handleInput(el, index, lesson);
                    }}
                    decor={"text-center"}
                  />
                </td>
                <td className="whitespace-nowrap px-6 py-4">
                  <Input
                    name="semester_3"
                    type="text"
                    label=""
                    description=""
                    value={scholarship?.academics?.[index]?.semester_3 ?? ""}
                    errors={{}}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/\D+/g, ""))
                    }
                    onChange={(el) => {
                      handleInput(el, index, lesson);
                    }}
                    decor={"text-center"}
                  />
                </td>
                <td className="whitespace-nowrap px-6 py-4">
                  <Input
                    name="semester_4"
                    type="text"
                    label=""
                    description=""
                    value={scholarship?.academics?.[index]?.semester_4 ?? ""}
                    errors={{}}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/\D+/g, ""))
                    }
                    onChange={(el) => {
                      handleInput(el, index, lesson);
                    }}
                    decor={"text-center"}
                  />
                </td>
                <td className="whitespace-nowrap px-6 py-4">
                  <Input
                    name="semester_5"
                    type="text"
                    label=""
                    description=""
                    value={scholarship?.academics?.[index]?.semester_5 ?? ""}
                    errors={{}}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/\D+/g, ""))
                    }
                    onChange={(el) => {
                      handleInput(el, index, lesson);
                    }}
                    decor={"text-center"}
                  />
                </td>
              </tr>
            ))}
            <tr className="border-b dark:border-neutral-500 bg-slate-200">
              <td
                colSpan={2}
                className="whitespace-nowrap px-6 py-4 text-center"
              >
                Jumlah
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {scholarship.sum_period?.sum_semester_1 ?? 0}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {scholarship.sum_period?.sum_semester_2 ?? 0}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {scholarship.sum_period?.sum_semester_3 ?? 0}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {scholarship.sum_period?.sum_semester_4 ?? 0}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {scholarship.sum_period?.sum_semester_5 ?? 0}
              </td>
            </tr>
            <tr className="border-b dark:border-neutral-500 bg-slate-300">
              <td
                colSpan={2}
                className="whitespace-nowrap px-6 py-4 text-center"
              >
                Rata - Rata
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {scholarship.avg_period?.avg_semester_1 ?? 0}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {scholarship.avg_period?.avg_semester_2 ?? 0}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {scholarship.avg_period?.avg_semester_3 ?? 0}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {scholarship.avg_period?.avg_semester_4 ?? 0}
              </td>
              <td className="whitespace-nowrap px-6 py-4 text-center">
                {scholarship.avg_period?.avg_semester_5 ?? 0}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScoreForm;
