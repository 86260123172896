import React from "react";
import { Link } from "react-router-dom";
import Button from "../Components/Button";

const ExamDetail = ({ data }) => {
  return (
    <div className="bg-slate-100">
      <h2 className="border-b border-blue-300 uppercase mb-3 py-2 px-4 bg-blue-600 text-white">
        {data?.name} {data?.exam_date}
      </h2>
      <div className="p-3">
        <div className="bg-slate-200 p-4 mb-3 rounded">
          Nama Pemetaan :
          <br />
          {data?.name}
        </div>
        <div className="bg-slate-200 p-4 mb-3 rounded">
          Lokasi Pemetaan :
          <br />
          {data?.location}
        </div>
        <div className="bg-slate-200 p-4 mb-3 rounded">
          Keterangan Pemetaan :
          <br />
          {data?.description}
        </div>
        <div className="bg-slate-200 p-4 mb-3 rounded">
          Tanggal Pemetaan :
          <br />
          {data?.exam_date}
        </div>
        <div className="bg-slate-200 p-4 mb-3 rounded">
          Lama Pemetaan :
          <br />
          {data?.time} Menit
        </div>
        <div className="mt-8 mb-3 text-center">
          <Link to={"/students/question"}>
            <Button>mulai ujian</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExamDetail;
