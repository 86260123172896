import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Button from "../Components/Button";
import TopBar from "../Components/TopBar";
import { loadingscreen } from "../Store";
import { get } from "../Utils/Axios";
import AnnouncementDetail from "./AnnouncementDetail";

const AnnouncementContainer = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [announcement, setAnnouncement] = useState(false);
  const { id } = useParams();
  const getAnnouncement = async () => {
    try {
      setLoading(true);
      const response = await get(`/students/announcement/${id}`);
      if (response.status === 200) {
        setAnnouncement(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error.data);
      setLoading(false);
    }
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (announcement === false) getAnnouncement();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);
  return (
    <div className="min-h-screen bg-slate-300">
      <TopBar />
      <div className="px-4 block mt-8">
        <AnnouncementDetail data={announcement} />
        <div className="flex justify-between bg-slate-100 p-3 mt-8 rounded">
          <Link to="/dashboards">
            <Button color="bg-orange-400">back</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementContainer;
