import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import TopBar from "../Components/TopBar";
import { authenticated, loadingscreen } from "../Store";
import { del, post, put } from "../Utils/Axios";
import ProfileForm from "./ProfileForm";

const ProfileContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const setLoading = useSetRecoilState(loadingscreen);
  const [records, setRecords] = useState({});
  const [errors, setErrors] = useState([]);
  const handleRecords = (el, value) => {
    const change = { ...records };
    const input = el.target;
    change[input.name] = value ?? input.value;
    setRecords(change);
  };
  const authUserChange = () => {
    const change = { ...auth.user };
    change.name = records.name;
    change.email = records.email;
    change.photo = records.photo;
    const changeAuth = { ...auth };
    changeAuth.user = change;
    setAuth(changeAuth);
    // console.log(records);
    return;
  };
  const updateProfile = async () => {
    try {
      setLoading(true);
      let profile = await put(`/students/profile/update`, records);
      if (profile.status === 200) {
        authUserChange();
        setErrors([]);
        Swal.fire({
          icon: "success",
          title: "Succcess",
          text: profile.data.message,
        });
      }
      setLoading(false);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);
    }
  };
  const uploadPhoto = async (el) => {
    const data = new FormData();
    data.append(el.target.name, el.target.files[0]);
    try {
      setLoading(true);
      const upload = await post(`students/profile/photo/upload`, data);
      if (upload.status === 200) {
        handleRecords(el, upload.data);
        setErrors([]);
        // console.log(upload.data);
      }
      setLoading(false);
    } catch (error) {
      //   console.log(error);
      setErrors(error.response);
      setLoading(false);
    }
  };
  const deletePhoto = async (el) => {
    try {
      setLoading(true);
      let response = await del(`/students/profile/photo/del`);
      if (response.status === 200) {
        const change = { ...records };
        change[el] = null;
        setRecords(change);
        setErrors([]);
      }
      setLoading(false);
    } catch (e) {
      setErrors(e.response);
      setLoading(false);
    }
  };
  useEffect(() => {
    authUserChange();
    // eslint-disable-next-line
  }, [records.photo]);
  useEffect(() => {
    setRecords({
      name: auth.user.name,
      email: auth.user.email,
      photo: auth.user.photo,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="min-h-screen bg-slate-300">
      <TopBar />
      <div className="px-4 block mt-8">
        <ProfileForm
          records={records}
          handleRecords={(el) => handleRecords(el)}
          uploadPhoto={(el) => uploadPhoto(el)}
          deletePhoto={(el) => deletePhoto(el)}
          errors={errors}
        />
        <div className="flex justify-between bg-slate-100 mt-6 p-3 rounded">
          <Link to={"/dashboards"}>
            <Button color="bg-orange-500">back</Button>
          </Link>
          <Button onClick={() => updateProfile()}>update</Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProfileContainer;
