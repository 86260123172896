import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import Swal from "sweetalert2";
import ParentsBiodataForm from "./Components/ParentsBiodataForm";
import StudentDetailedForm from "./Components/StudentDetailedForm";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import TopBar from "../Components/TopBar";
import { authenticated, loadingscreen } from "../Store";
import { get, put } from "../Utils/Axios";
import StudentFileForm from "./StudentFileForm";
import StudentReForm from "./StudentReForm";

const ReContainer = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const setLoading = useSetRecoilState(loadingscreen);
  const [records, setRecords] = useState(false);
  const [errors, setErrors] = useState([]);
  const authUserChange = (apply) => {
    const user = { ...auth.user };
    user.name = records.name;
    user.discount_application = apply;
    user.photo = records.photo.location;
    const changeAuth = { ...auth };
    changeAuth.user = user;
    setAuth(changeAuth);
    return;
  };
  const getBiodata = async () => {
    try {
      setLoading(true);
      const biodata = await get("/students/biodata");
      if (biodata.status === 200) {
        setRecords(biodata.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.data);
    }
  };
  const updateBiodata = async () => {
    try {
      setLoading(true);
      let biodata = await put(`/students/biodata/reupdate`, records);
      if (biodata.status === 200) {
        authUserChange(biodata.data.discount_apply);
        Swal.fire({
          icon: "success",
          title: "Succcess",
          text: biodata.data.message,
        });
        setErrors([]);
      }
      setLoading(false);
    } catch (e) {
      setErrors(e.response.data);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.response.data.message,
        footer: "Periksa form biodata kembali",
      });
    }
  };
  const handleRecords = (el) => {
    const change = { ...records };
    const input = el.target;
    change[input.name] = input.value;
    setRecords(change);
    // console.log(recordsRef.current);
  };
  const handleRecordsOnlyNumber = (el) => {
    // console.log(typeof recordsRef.current.nisn);
    const change = { ...records };
    const input = el.target;
    const value = input.value;
    let thenum = value.replace(/\D/g, "");
    change[input.name] = thenum;
    setRecords(change);
    // console.log(recordsRef.current);
  };
  useEffect(() => {
    if (records === false) getBiodata();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-slate-300 min-h-screen flex flex-col justify-between">
      <TopBar />
      <div className="px-4 block mt-8">
        <div className="p-3 bg-blue-500 font-bold rounded text-center text-white shadow-xl">
          DAFTAR ULANG
        </div>
      </div>
      <div className="px-4 block mt-8">
        <span className="p-3 bg-green-400 font-bold rounded">
          NO PENDAFTARAN {auth.user.no_register}
        </span>
      </div>
      <div className="px-4 block mt-4">
        {errors.message ? (
          <div className="p-3 bg-red-400 font-bold rounded text-white">
            {errors.message}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="p-4">
        {records.name ? (
          <Fragment>
            <StudentReForm
              records={records}
              handleRecords={(e) => handleRecords(e)}
              handleRecordsOnlyNumber={(e) => handleRecordsOnlyNumber(e)}
              errors={errors}
            />
            <ParentsBiodataForm
              records={records}
              handleRecords={(e) => handleRecords(e)}
              handleRecordsOnlyNumber={(e) => handleRecordsOnlyNumber(e)}
              errors={errors}
            />
            <StudentDetailedForm
              records={records}
              handleRecords={(e) => handleRecords(e)}
              handleRecordsOnlyNumber={(e) => handleRecordsOnlyNumber(e)}
              errors={errors}
            />
            <StudentFileForm
              records={records}
              setRecords={(data) => setRecords(data)}
              errors={errors}
              setErrors={(error) => setErrors(error)}
            />
          </Fragment>
        ) : (
          ""
        )}
        <div className="flex justify-between bg-slate-100 p-3 mt-8 rounded">
          <Link to="/dashboards">
            <Button color="bg-orange-400">back</Button>
          </Link>
          <Button onClick={() => updateBiodata()}>update</Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReContainer;
