import React from "react";
import ScholarshipFile from "./ScholarshipFile";

const ScholarshipBasketForm = ({ type }) => {
  return (
    <div>
      <ScholarshipFile
        file_name={"report_file"}
        label={"Rapot semester 1 sampai 5"}
        type={type}
      />
      <ScholarshipFile
        file_name={"health_file"}
        label={"Surat Kesehatan"}
        type={type}
      />
      <ScholarshipFile
        file_name={"napza_file"}
        label={"Surat Napza"}
        type={type}
      />
      <ScholarshipFile
        file_name={"recomendation_basket_file"}
        label={"Surat Rekomendasi Club Basket"}
        type={type}
      />
      <ScholarshipFile
        file_name={"championship_basket_file"}
        label={"Sertifikat Kejuaraan Basket"}
        type={type}
      />
    </div>
  );
};

export default ScholarshipBasketForm;
