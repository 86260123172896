import React, { useEffect, useState } from "react";
import { get } from "../../Utils/Axios";
import { useSetRecoilState } from "recoil";
import { loadingscreen } from "../../Store";

const MethodList = ({ bill, method, setMethod }) => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [payments, setPayments] = useState([]);

  const getPayments = async () => {
    try {
      setLoading(true);
      let response = await get(`/ipaymus/methods?type=${bill.type}`);
      if (response.status === 200) {
        setPayments(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPayments();

    return () => {};
  }, []);

  return (
    <div>
      <h1 className="text-3xl font-bold mb-4">Payment List</h1>
      <div className="grid grid-cols-4 gap-4 gap-y-4 w-full">
        {payments.map((payment, index) => (
          <div
            className={`border p-1 rounded-md cursor-pointer transition-all ${
              method.code === payment.code ? "bg-green-400" : ""
            }`}
            key={index}
            onClick={() => setMethod(payment)}
          >
            <img className="w-full" src={payment.icon} alt={payment.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MethodList;
