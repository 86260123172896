import React, { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import Footer from "../Components/Footer";
import TopBar from "../Components/TopBar";
import { dashboards, loadingscreen } from "../Store";
import { get } from "../Utils/Axios";
import Announcements from "./Announcements";
import DashboardStepBox from "./DashboardStepBox";
import DashboardTopBox from "./DashboardTopBox";
import NotifyBox from "./NotifyBox";

const DashboardContainer = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [dashboard, setDashboard] = useRecoilState(dashboards);
  const getStudentDashboard = async () => {
    try {
      setLoading(true);
      const response = await get("/students/dashboard");
      if (response.status === 200) {
        setDashboard(response.data);
      }
      setLoading(false);
    } catch (error) {
      // console.log(error.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getStudentDashboard();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{ backgroundImage: `url(./dashboard-bg.png)` }}
      className="bg-slate-200 min-h-screen flex flex-col justify-between bg-no-repeat bg-left-bottom"
    >
      <TopBar />
      {dashboard ? (
        <div className="flex flex-col lg:flex-row p-3 mt-8 gap-4">
          <div className="w-full lg:w-1/5 bg-[#000066] p-4 rounded-2xl text-white shadow-md">
            <h2 className="text-2xl mb-4 font-bold">PETUNJUK UMUM</h2>
            <div className="flex flex-col justify-between">
              <div>
                <Announcements />
              </div>
              <a
                className="bg-white text-center py-3 mt-24 rounded-full text-black"
                href={process.env.REACT_APP_HELP_WA}
                target={"blank"}
                rel="noreferrer"
              >
                Hubungi Admin
              </a>
            </div>
          </div>
          <div className="w-full lg:w-4/5">
            <DashboardTopBox
              bills={dashboard.student.bills}
              exam={dashboard.student.exam}
              quran={dashboard.student.quran}
              announcements={dashboard.announcements}
            />
            <NotifyBox message={dashboard.student.notify} />
            <DashboardStepBox
              satCount={dashboard.student.sat_count}
              step={dashboard.student.step}
              steps={dashboard.student.steps}
              all_steps={dashboard.student.all_steps}
              formulir={dashboard.student.box_formulir}
              exam={dashboard.student.exam}
              quran={dashboard.student.quran}
              sat={dashboard.student.box_sat}
              scholarship_status={dashboard.student.scholarship_status}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
};

export default DashboardContainer;
