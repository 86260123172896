import React from "react";
import Input from "../../Components/Input";
import Select from "../../Components/Select";

const ParentsBiodataForm = ({
  records,
  handleRecords,
  handleRecordsOnlyNumber,
  errors,
}) => {
  return (
    <div className="bg-slate-100 mt-8">
      <h2 className="border-b border-blue-300 uppercase mb-3 py-2 px-4 bg-blue-600 text-white">
        data orang tua kandung
      </h2>
      <div className="p-3">
        <Input
          name="fathername"
          type="text"
          label="19. Nama Ayah Kandung (*)"
          value={records.fathername ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="fathernik"
          type="text"
          label="20. NIK Ayah (*)"
          value={records.fathernik ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Input
          name="fatherbirthyear"
          type="text"
          label="21. Tahun Lahir (*)"
          value={records.fatherbirthyear ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Input
          name="fathereducation"
          type="text"
          label="22. Pendidikan (*)"
          value={records.fathereducation ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="fatherjob"
          type="text"
          label="23. Pekerjaan (*)"
          value={records.fatherjob ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="fathersalary"
          type="text"
          label="24. Penghasilan Bulanan (*)"
          value={records.fathersalary ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Select
          name="fatherstat"
          type="text"
          label="25. Status Ayah (*)"
          options={["Hidup", "Meninggal"]}
          value={records.fatherstat ?? ""}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
        <Input
          name="fatherphone"
          type="text"
          label="26. Nomer Ponsel Ayah (*)"
          value={records.fatherphone ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Input
          name="fathermail"
          type="email"
          label="27. Email Ayah (*)"
          value={records.fathermail ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="mothername"
          type="text"
          label="28. Nama Ibu Kandung (*)"
          value={records.mothername ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="mothernik"
          type="text"
          label="29. NIK Ibu (*)"
          value={records.mothernik ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Input
          name="motherbirthyear"
          type="text"
          label="30. Tahun Lahir (*)"
          value={records.motherbirthyear ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Input
          name="mothereducation"
          type="text"
          label="31. Pendidikan (*)"
          value={records.mothereducation ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="motherjob"
          type="text"
          label="32. Pekerjaan (*)"
          value={records.motherjob ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="mothersalary"
          type="text"
          label="33. Penghasilan Bulanan (*)"
          value={records.mothersalary ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Select
          name="motherstat"
          type="text"
          label="34. Status Ibu (*)"
          options={["Hidup", "Meninggal"]}
          value={records.motherstat ?? ""}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
        <Input
          name="motherphone"
          type="text"
          label="35. Nomer Ponsel Ibu (*)"
          value={records.motherphone ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Input
          name="mothermail"
          type="email"
          label="36. Email Ibu (*)"
          value={records.mothermail ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
      </div>
    </div>
  );
};

export default ParentsBiodataForm;
