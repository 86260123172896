import React, { Fragment } from "react";

const QuestionNumber = ({ exam_id, numbers, question, getQuestion }) => {
  // const consoleLog = () => {
  //   console.log(remaining_minutes);
  // };
  return (
    <div className="flex flex-wrap gap-3 justify-around">
      {exam_id ? (
        <Fragment>
          {numbers.map((item, index) => (
            <div
              key={index}
              className={`${
                item.answer === "y" ? "bg-green-400" : "bg-slate-300"
              } px-3 py-1 rounded cursor-pointer hover:bg-orange-400 ${
                parseInt(question.id) === item.question_id
                  ? "bg-red-500 text-white"
                  : "bg-slate-300"
              }`}
              onClick={() => getQuestion(item.question_id, item.number)}
            >
              {item.number}
            </div>
          ))}
        </Fragment>
      ) : (
        ""
      )}
    </div>
  );
};

export default QuestionNumber;
