import React from "react";

const Announcements = () => {
  return (
    <div className="flex flex-col gap-3 py-2 text-sm font-bold">
      <div className="flex gap-2">
        <div>1.</div>
        <div>Pastikan data yang kalian isi sudah benar</div>
      </div>
      <div className="flex gap-2">
        <div>2.</div>
        <div>
          Setiap tahapan pendaftaran kalian akan mendapatkan informasi berupa
          email
        </div>
      </div>
      <div className="flex gap-2">
        <div>3.</div>
        <div>Ujian menggunakan laptop</div>
      </div>
      <div className="flex gap-2">
        <div>4.</div>
        <div>Hubungi admin jika ada kendala</div>
      </div>
      <div className="flex gap-2">
        <div>5.</div>
        <div>Harap mendownload invoice ketika sudah melakukan transaksi</div>
      </div>
    </div>
  );
};

export default Announcements;
