import axios from "axios";
export const get = async (url) => {
  let response = await axios.get(`${url}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  return response;
};
export const post = async (url, data) => {
  let response = await axios.post(`${url}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  return response;
};

export const put = async (url, data) => {
  let response = await axios.put(`${url}`, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  return response;
};
export const del = async (url) => {
  let response = await axios.delete(`${url}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  return response;
};
export const downloadPdf = async (url, filename) => {
  let response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      Accept: "application/octet-stream",
    },
    responseType: "blob",
  });
  if (response.status === 200) {
    const blob = new Blob([response.data], {
      type: "application/pdf",
    });
    const fileUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }
  return response;
};
// export const getImage = () => {
//   let url = "https://api.laravel-ppdb.test/api/file/familycardfile";
//   axios
//     .get(url, {
//       headers: {
//         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
//       },
//       responseType: "arraybuffer",
//     })
//     .then((res) => {
//       const base64 = btoa(
//         new Uint8Array(res.data).reduce(
//           (data, byte) => data + String.fromCharCode(byte),
//           ""
//         )
//       );
//       return base64;
//       // setImage(base64);
//     });
// };
