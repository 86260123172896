import React, { useEffect } from "react";
import { get } from "../Utils/Axios";
const Timer = ({ exam, countDownRef, doneExam, refreshTime, refresh }) => {
  const timeOut = (timer) => {
    timer = parseInt(timer);
    if (timer === 0) {
      clearInterval(countDownRef.current);
      doneExam();
      //   to_route("/dashboards");
    }
  };
  const startTimer = () => {
    let hours = exam.time.hours;
    let minutes = exam.time.minutes;
    let seconds = exam.time.seconds;
    countDownRef.current = setInterval(() => {
      // console.clear();
      let detik = seconds < 10 ? `0${seconds}` : seconds;
      let menit = minutes < 10 ? `0${minutes}` : minutes;
      let display = hours + ":" + menit + ":" + detik;
      //   console.log(display);
      let show = document.getElementById("counter");
      if (show === null) {
        clearInterval(countDownRef.current);
      }
      if (show !== null) {
        show.innerText = display;
      }
      if (--seconds < 0) {
        seconds = 59;
        if (--minutes < 0) {
          --hours;
          minutes = 59;
        }
      }
      timeOut(`${hours}${minutes}${seconds}`);
    }, 1000);
  };

  const refreshTimer = (hoursSet, minutesSet, secondsSet) => {
    let hours = hoursSet;
    let minutes = minutesSet;
    let seconds = secondsSet;
    countDownRef.current = setInterval(() => {
      // console.clear();
      let detik = seconds < 10 ? `0${seconds}` : seconds;
      let menit = minutes < 10 ? `0${minutes}` : minutes;
      let display = hours + ":" + menit + ":" + detik;
      //   console.log(display);
      let show = document.getElementById("counter");
      if (show === null) {
        clearInterval(countDownRef.current);
      }
      if (show !== null) {
        show.innerText = display;
      }
      if (--seconds < 0) {
        seconds = 59;
        if (--minutes < 0) {
          --hours;
          minutes = 59;
        }
      }
      timeOut(`${hours}${minutes}${seconds}`);
    }, 1000);
  };

  useEffect(() => {
    if (typeof countDownRef.current !== "undefined") {
      clearInterval(countDownRef.current);
    }
    startTimer();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const syncTime = async () => {
      try {
        const sync = await get("sync");
        if (sync.status === 200) {
          if (typeof countDownRef.current !== "undefined") {
            clearInterval(countDownRef.current);
          }
          refreshTimer(sync.data.hours, sync.data.minutes, sync.data.seconds);
          return sync.data;
        }
      } catch (error) {}
    };
    if (refreshTime) {
      syncTime();
    }
  }, [refresh]);

  return (
    <div>
      <div className="block text-lg font-bold" id="counter"></div>
    </div>
  );
};

export default Timer;
