import React from "react";

const QuestionDetail = ({ question, handleAnswer }) => {
  const alpabhet = (index) => {
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    const alphabet = alpha.map((x) => String.fromCharCode(x));
    return alphabet[index];
  };
  return (
    <div>
      {question ? (
        <div>
          <div
            className="block"
            dangerouslySetInnerHTML={{ __html: question?.content }}
          ></div>
          <div className="block mt-4">
            {question.options.map((option, index) => (
              <div
                className="block bg-slate-200 rounded mb-3 p-3"
                key={option.id}
              >
                <div className="flex">
                  <div>
                    <span className="mr-3 font-bold">{alpabhet(index)}.</span>
                    <input
                      name="answer_id"
                      className="mr-2"
                      type={"radio"}
                      value={option.id}
                      checked={option.id === question.answer_id}
                      onChange={() => handleAnswer(option.id)}
                    />
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: option?.content }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default QuestionDetail;
