import React from "react";
const AnnouncementDetail = ({ data }) => {
  return (
    <div className="bg-slate-100">
      <h2 className="border-b border-blue-300 uppercase mb-3 py-2 px-4 bg-blue-600 text-white">
        {data?.name} {data?.created_at}
      </h2>
      <div className="p-3">
        <div className="bg-slate-200 p-4 mb-3 rounded">{data?.content}</div>
      </div>
    </div>
  );
};

export default AnnouncementDetail;
