import React from "react";
import ScoreForm from "./ScoreForm";
import ScholarshipFile from "./ScholarshipFile";

const ScholarshipAcademicForm = ({ type }) => {
  return (
    <div>
      <ScoreForm />
      <ScholarshipFile
        file_name={"report_file"}
        label={"Rapot semester 1 sampai 5"}
        type={type}
      />
      <ScholarshipFile
        file_name={"health_file"}
        label={"Surat Kesehatan"}
        type={type}
      />
      <ScholarshipFile
        file_name={"napza_file"}
        label={"Surat Napza"}
        type={type}
      />
      <ScholarshipFile
        file_name={"class_rank_file"}
        label={"Surat Keterangan Ranking Kelas"}
        type={type}
      />
      <ScholarshipFile
        file_name={"force_rank_file"}
        label={"Surat Keterangan Ranking Angkatan"}
        type={type}
      />
      <ScholarshipFile
        file_name={"academic_race_file"}
        label={"Sertifikat lomba maksimal 3 terbaik, terbaru dan pada masa smp"}
        type={type}
      />
    </div>
  );
};

export default ScholarshipAcademicForm;
