import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Button from "../Components/Button";
import Footer from "../Components/Footer";
import TopBar from "../Components/TopBar";
import { loadingscreen } from "../Store";
import { get } from "../Utils/Axios";
import ExamDetail from "./ExamDetail";

const ExamContainer = () => {
  const setLoading = useSetRecoilState(loadingscreen);
  const [exam, setExam] = useState(false);
  const getExam = async () => {
    try {
      setLoading(true);
      const response = await get(`/students/exam`);
      if (response.status === 200) {
        setExam(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error.data);
      setLoading(false);
    }
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (exam === false) getExam();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);
  return (
    <div className="bg-slate-300 min-h-screen flex flex-col justify-between">
      <TopBar />
      <div className="px-4 block mt-8">
        <ExamDetail data={exam} />
        <div className="flex justify-between bg-slate-100 p-3 mt-8 rounded">
          <Link to="/dashboards">
            <Button color="bg-orange-400">back</Button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ExamContainer;
