import React, { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { assetsconfig, authenticated } from "../Store";
import { post } from "../Utils/Axios";

const TopBar = () => {
  const [auth, setAuth] = useRecoilState(authenticated);
  const assets = useRecoilValue(assetsconfig);
  const [menu, setMenu] = useState(false);
  const logOut = async () => {
    try {
      const logout = await post("/students/logout", "");
      if (logout.status === 200) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("lastpath");
        setAuth({ login: false, user: {} });
      }
    } catch (error) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("lastpath");
      setAuth({ login: false, user: {} });
      // console.log(error.data);
    }
  };
  return (
    <div className="min-w-screen bg-[#000066] text-white p-3">
      <div className="flex justify-between">
        <Link to={"/dashboards"}>{auth.user.name}</Link>
        <div className="cursor-pointer relative">
          <AiOutlineMenu size={30} onClick={() => setMenu(!menu)} />
          {menu ? (
            <div className="absolute top-8 right-0 bg-yellow-50 w-40 text-gray-600 uppercase animate__animated animate__fadeInDown">
              <Link
                to={"/dashboards"}
                className="p-2 block border-b-2 hover:bg-green-300"
              >
                dashboards
              </Link>
              <Link
                to={"/students/profile"}
                className="p-2 block border-b-2 hover:bg-green-300"
              >
                profil
              </Link>
              <a
                href={`https://api.whatsapp.com/send/?phone=${assets?.humas_phone}&text&type=phone_number&app_absent=0`}
                target={"blank"}
                rel="noreferrer"
                className="p-2 block border-b-2 hover:bg-green-300"
              >
                help
              </a>
              <button
                className="p-2 w-full border-b-2 hover:bg-green-300 bg-red-500 text-white"
                onClick={() => logOut()}
              >
                logout
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
