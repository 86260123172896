import React from "react";
import Input from "../Components/Input";
import Select from "../Components/Select";
import Textarea from "../Components/Textarea";

const StudentReForm = ({
  records,
  handleRecords,
  handleRecordsOnlyNumber,
  errors,
}) => {
  return (
    <div className="bg-slate-100 mt-8">
      <h2 className="border-b border-blue-300 uppercase mb-3 py-2 px-4 bg-blue-600 text-white">
        daftar ulang siswa
      </h2>
      <div className="p-3">
        <Input
          name="name"
          type="text"
          label="1. Nama Lengkap Peserta Didik (*)"
          description="nama sesuai Dokumen resmi yang berlaku (Akta lahir, KK, atau Ijazah sebelumnya)"
          value={records?.name}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Select
          name="gender"
          type="text"
          label="2. Jenis kelamin (*)"
          options={["Laki - Laki", "Perempuan"]}
          value={records.gender ?? ""}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
        <Select
          name="interest"
          type="text"
          label="3. Minat Jurusan (*)"
          value={records.interest ?? ""}
          options={["REGULER", "TAHFIDZ"]}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
        <Input
          name="nisn"
          type="text"
          label="4. NISN (Nomer Induk Siswa Nasional) (*)"
          description="(NISN memiliki format 10 digit angka.Contoh: 000932123)"
          value={records.nisn ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Input
          name="nik"
          type="text"
          label="5. NIK / No. KITAS (Untuk WNA) (*)"
          description="Nomor Induk Kependudukan yang tercantum pada Kartu Keluarga, Kartu Identitas Anak,
          atau KTP (jika sudah memiliki) bagi WNI. NIK memiliki format 16 digit angka. Contoh:
          6112090906021104"
          value={records.nik ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Input
          name="kk"
          type="text"
          label="6. No. KK (*)"
          description="Pastikan No. Kartu Keluarga, tidak tertukar dengan No. NIK karena keduanya memiliki
          format yang sama"
          value={records.kk ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
        <Input
          name="birthplace"
          type="text"
          label="7. Tempat Lahir (*)"
          description="Tempat lahir peserta didik sesuai dokumen resmi yang berlaku"
          value={records.birthplace ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="birthdate"
          type="date"
          label="8. Tanggal Lahir (*)"
          description="Tanggal lahir peserta didik sesuai dokumen resmi yang berlaku"
          value={records.birthdate ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="numberbirth"
          type="text"
          label="9. No. Registasi Akta Lahir (*)"
          description="Nomor registrasi Akta Kelahiran. Nomor registrasi yang dimaksud umumnya tercantum
          pada bagian tengah atas lembar kutipan akta kelahiran"
          value={records.numberbirth ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Select
          name="religion"
          type="text"
          label="10. Agama (*)"
          value={records.religion ?? ""}
          options={[
            "Islam",
            "Kristen/Protestan",
            "Katholik",
            "Hindu",
            "Budha",
            "Khonghucu",
          ]}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
        <Select
          name="citizenship"
          type="text"
          label="11. Kewarganegaraan (*)"
          value={records.citizenship ?? ""}
          options={["Indonesia (WNI)", "Asing (WNA)"]}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
        <Select
          name="specialneeds"
          type="text"
          label="12. Berkebutuhan Khusus (*)"
          value={records.specialneeds ?? ""}
          options={["Ya", "Tidak"]}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
        <Textarea
          name="address"
          label="13. Alamat Lengkap (*)"
          rows="4"
          value={records.address ?? ""}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
        <Select
          name="residence"
          type="text"
          label="14. Tempat Tinggal (*)"
          value={records.residence ?? ""}
          options={[
            "Bersama Orang Tua",
            "Wali",
            "Kos",
            "Asrama",
            "Panti Asuhan",
          ]}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
        <Select
          name="transportation"
          type="text"
          label="15. Modal Transportasi (*)"
          value={records.transportation ?? ""}
          options={[
            "Jalan kaki",
            "Kendaraan Pribadi",
            "Kendaraan Umum/angkot",
            "Ojek",
            "Lainnya",
          ]}
          onChange={(e) => handleRecords(e)}
          errors={errors}
        />
        <Input
          name="childto"
          type="text"
          label="16. Anak Keberapa (*)"
          value={records.childto ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="sumbrother"
          type="text"
          label="17. Jumlah saudara kandung (*)"
          value={records.sumbrother ?? ""}
          errors={errors}
          onChange={(e) => handleRecords(e)}
        />
        <Input
          name="phone"
          type="tel"
          label="18. Nomor Whatsapp Orang Tua (*)"
          description="Wajib diisi untuk pemberitahuan"
          value={records.phone ?? ""}
          errors={errors}
          onChange={(e) => handleRecordsOnlyNumber(e)}
        />
      </div>
    </div>
  );
};

export default StudentReForm;
