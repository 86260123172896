import React from "react";
import {
  FaCheckCircle,
  FaInfoCircle,
  FaRegCircle,
  FaTimesCircle,
} from "react-icons/fa";

const StatusBoxStudent = ({ status }) => {
  const statusIconBox = (status) => {
    if (status === "diterima") {
      return <FaCheckCircle size={40} className="mx-auto mb-4" />;
    } else if (status === "tidak diterima") {
      return <FaTimesCircle size={40} className="mx-auto mb-4" />;
    } else if (status === "mengundurkan diri") {
      return <FaInfoCircle size={40} className="mx-auto mb-4" />;
    } else {
      return <FaRegCircle size={40} className="mx-auto mb-4" />;
    }
  };
  return <div>{statusIconBox(status)}</div>;
};

export default StatusBoxStudent;
